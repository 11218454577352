<template>
  <div class="viewAddRecipe">
    
    <h2>Nowy przepis</h2>

    <br />

    <Recipe :formAction=1 />
    
  </div>
</template>

<script>
import Recipe from "@/components/Recipe.vue"

export default {
  name: "ViewAddRecipe",
  components: {
    Recipe
  }
}
</script>


<style scoped>
.divPanel {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
</style>